var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-menu"},[(
      !_vm.$store.getters.setPanel ||
        (_vm.$store.getters.setPanel && _vm.permissions.DashboardRead)
    )?_c('item',{attrs:{"route":"Dashboard","name":"Dashboard","icon":"dashboard"}},[_vm._v("Dashboard")]):_vm._e(),(
      !_vm.$store.getters.setPanel ||
        (_vm.$store.getters.setPanel && _vm.permissions.SaleRead)
    )?_c('item',{attrs:{"route":"Sales","name":_vm.$t('side.text_1952'),"icon":"my-sales"}}):_vm._e(),(!_vm.$store.getters.setPanel)?_c('item',{attrs:{"route":"Contracts","name":_vm.$t('side.text_1953'),"icon":"my-contracts"}}):_vm._e(),(
      !_vm.$store.getters.setPanel && _vm.ambassadorEnabled && _vm.ambassadorAcceptedTerms)?_c('item',{attrs:{"route":"Comissions","name":_vm.$t('side.text_1976'),"icon":"my-comissions"}}):(
      !_vm.$store.getters.setPanel && _vm.ambassadorEnabled && !_vm.ambassadorAcceptedTerms)?_c('item',{attrs:{"route":"ComissionsTerms","name":_vm.$t('side.text_1976'),"icon":"my-comissions"}}):_vm._e(),(
      !_vm.$store.getters.setPanel ||
        (_vm.$store.getters.setPanel && _vm.permissions.ProductRead)
    )?_c('item',{attrs:{"route":"Products","name":_vm.$t('side.text_1954'),"icon":"products"}}):_vm._e(),(
      !_vm.$store.getters.setPanel ||
        (_vm.$store.getters.setPanel && _vm.permissions.LinkRead)
    )?_c('item',{attrs:{"route":"Links","name":_vm.$t('side.text_1955'),"icon":"links"}}):_vm._e(),(!_vm.$store.getters.setPanel)?_c('item',{attrs:{"route":"Statements","name":_vm.$t('side.text_1956'),"icon":"extrato"}}):_vm._e(),(
      !_vm.$store.getters.setPanel ||
        (_vm.$store.getters.setPanel && _vm.permissions.CouponRead)
    )?_c('item',{attrs:{"route":"Coupon","name":_vm.$t('side.text_1957'),"icon":"cupom"}}):_vm._e(),(!_vm.$store.getters.setPanel)?_c('item',{attrs:{"route":"NoteCenter","name":_vm.$t('side.text_1958'),"icon":"notas"}}):_vm._e(),(!_vm.$store.getters.setPanel)?_c('item',{attrs:{"route":"Checkouts","name":_vm.$t('side.text_1959'),"icon":"grid"}}):_vm._e(),(!_vm.$store.getters.setPanel)?_c('item',{attrs:{"route":"Affiliation","name":_vm.$t('side.text_1960'),"icon":"affiliate"}}):_vm._e(),(!_vm.$store.getters.setPanel)?_c('item',{attrs:{"route":"Leads","name":_vm.$t('side.text_1961'),"icon":"award"}}):_vm._e(),(!_vm.$store.getters.setPanel)?_c('item',{attrs:{"route":"Reclamações","name":_vm.$t('side.text_1962'),"icon":"triangle"}}):_vm._e(),_c('item',{attrs:{"route":"MyAccount","name":"Minha Conta","icon":"user-config"}}),_c('item',{attrs:{"route":"reports","name":"Relatórios","icon":"relatorios"}}),_c('item',{attrs:{"name":"Voomp Play","icon":"arrow-club","onClick":_vm.eventLoginClub}}),(!_vm.$store.getters.setPanel)?_c('item',{attrs:{"route":"Settings","name":"Configurações","icon":"company-config"}}):_vm._e(),(!_vm.$store.getters.setPanel)?_c('item',{attrs:{"onClick":_vm.openHelpLink,"name":_vm.$t('side.text_1977'),"icon":"help"}}):_vm._e(),_c('div',{staticClass:"Menu-item-logout",on:{"click":function($event){$event.preventDefault();return _vm.logout.apply(null, arguments)}}},[_c('a',{attrs:{"href":"#"}},[_c('img',{attrs:{"src":require("@/assets/img/icons/sign-out.svg")}}),_vm._v(" "+_vm._s(_vm.$t("side.text_1964"))+" ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }