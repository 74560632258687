import Cookies from 'js-cookie';
import router from '@/router/index'


export default {

    userSuccess: (state, resp) => {
        state.user = resp;
        localStorage.user = JSON.stringify(resp)
        Cookies.set('user', JSON.stringify(resp), {
            expires: 365
        });
    },
    userClub: (state, resp) => {
        state.userClub = resp;
        Cookies.set('club_integration', JSON.stringify(resp), {
            expires: 365
        });
    },
    verifyEmail: (state, resp) => {
        state.verifyEmail = resp;
        Cookies.set('verify_email', JSON.stringify(resp), {
            expires: 365
        });
    },
    setPanel: (state, resp) => {
        state.setPanel = resp;
        Cookies.set('setPanel', resp, {
            expires: 7
        });
    },
    resetPanel: (state, resp) => {
        state.setPanel = null;
        Cookies.remove('setPanel');
    },
    adminUserSuccess: (state, resp) => {
        state.user = resp;
    },
    userLogout: (state) => {
        const locationadm = localStorage.locationadm
        state.user = null;
        state.userClub = null;
        state.verifyEmail = null
        state.setPanel = null
        state.access_token = '';
        Cookies.remove('access_token');
        Cookies.remove("setPanelName");
        Cookies.remove("setPanelPermission");
        Cookies.remove('setPanel');
        Cookies.remove('club_integration');
        Cookies.remove('user');
        Cookies.remove('verify_email');
        sessionStorage.clear();
        localStorage.clear();
        localStorage.locationadm = locationadm;
        window.location.href = window.location.protocol + "//" + window.location.host
    }

}