<template>
  <div class="container-menu">
    <item
      v-if="
        !$store.getters.setPanel ||
          ($store.getters.setPanel && permissions.DashboardRead)
      "
      route="Dashboard"
      name="Dashboard"
      icon="dashboard"
      >Dashboard</item
    >
    <item
      v-if="
        !$store.getters.setPanel ||
          ($store.getters.setPanel && permissions.SaleRead)
      "
      route="Sales"
      :name="$t('side.text_1952')"
      icon="my-sales"
    />
    <item
      v-if="!$store.getters.setPanel"
      route="Contracts"
      :name="$t('side.text_1953')"
      icon="my-contracts"
    />
    <item
      v-if="
        !$store.getters.setPanel && ambassadorEnabled && ambassadorAcceptedTerms"
      route="Comissions"
      :name="$t('side.text_1976')"
      icon="my-comissions"
    />
    <item
      v-else-if="
        !$store.getters.setPanel && ambassadorEnabled && !ambassadorAcceptedTerms"
      route="ComissionsTerms"
      :name="$t('side.text_1976')"
      icon="my-comissions"
    />
    <item
      v-if="
        !$store.getters.setPanel ||
          ($store.getters.setPanel && permissions.ProductRead)
      "
      route="Products"
      :name="$t('side.text_1954')"
      icon="products"
    />
    <!-- <item route="Vitrine" name="Vitrine" icon="checkout-link" /> -->
    <item
      v-if="
        !$store.getters.setPanel ||
          ($store.getters.setPanel && permissions.LinkRead)
      "
      route="Links"
      :name="$t('side.text_1955')"
      icon="links"
    />
    <item
      v-if="!$store.getters.setPanel"
      route="Statements"
      :name="$t('side.text_1956')"
      icon="extrato"
    />
    <item
      v-if="
        !$store.getters.setPanel ||
          ($store.getters.setPanel && permissions.CouponRead)
      "
      route="Coupon"
      :name="$t('side.text_1957')"
      icon="cupom"
    />
    <item
      v-if="!$store.getters.setPanel"
      route="NoteCenter"
      :name="$t('side.text_1958')"
      icon="notas"
    />
    <item
      v-if="!$store.getters.setPanel"
      route="Checkouts"
      :name="$t('side.text_1959')"
      icon="grid"
    />
    <item
      v-if="!$store.getters.setPanel"
      route="Affiliation"
      :name="$t('side.text_1960')"
      icon="affiliate"
    />
    <item
      v-if="!$store.getters.setPanel"
      route="Leads"
      :name="$t('side.text_1961')"
      icon="award"
    />
    <item
      v-if="!$store.getters.setPanel"
      route="Reclamações"
      :name="$t('side.text_1962')"
      icon="triangle"
    />
    <item route="MyAccount" name="Minha Conta" icon="user-config" />
    <item route="reports" name="Relatórios" icon="relatorios" />
    <item name="Voomp Play" icon="arrow-club" :onClick="eventLoginClub" />
    <item
      v-if="!$store.getters.setPanel"
      route="Settings"
      name="Configurações"
      icon="company-config"
    />
    <item
      v-if="!$store.getters.setPanel"
      :onClick="openHelpLink"
      :name="$t('side.text_1977')"
      icon="help"
    />
    <div class="Menu-item-logout" @click.prevent="logout">
      <a href="#">
        <img src="@/assets/img/icons/sign-out.svg" />
        {{ $t("side.text_1964") }}
      </a>
    </div>
  </div>
</template>
<script>
import Item from "./Item";
import Vue from "vue";
import Cookies from "js-cookie";

export default {
  data() {
    return {
      permissions: {},
    };
  },
  components: {
    Item,
  },
  computed: {
    isSeller() {
      return this.$store.getters.isSeller;
    },
    ambassadorEnabled() {
      return this.$store.getters.getProfile.ambassador_enabled;
    },
    ambassadorAcceptedTerms() {
      return this.$store.getters.getProfile.ambassador_accepted_terms;
    },
  },
  mounted() {
    this.getPermissions();
    this.$root.$on("reset-all", (data) => {
      this.getPermissions();
    });
    this.$root.$on("reset-permissions", (data) => {
      this.getPermissions();
    });
  },
  methods: {
    eventLoginClub() {
      Vue.swal({
        title: this.$t("side.text_1973"),
        text: this.$t("side.text_1971"),
        type: "info",
        showCancelButton: true,
        confirmButtonText: "Entrar no Voomp Play",
        cancelButtonText: this.$t("side.text_1975"),
        customClass: "sweet-container",
        confirmButtonClass: "button button-black mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("clubLogin").catch(function() {});
        }
        if (result.dismiss == "cancel") {
          this.$router.push("/produtos");
        }
      });
    },
    getPermissions() {
      if (this.$store.getters.setPanel) {
        var dados = Cookies.get("setPanelPermission");
        if (dados) {
          dados = JSON.parse(dados);
          this.permissions = dados.reduce((obj, item) => {
            obj[item.name] = true;
            return obj;
          }, {});
        }
      }
    },
    logout() {
      this.$store.dispatch("logoutRequest").catch(function() {});
    },
    openHelpLink() {
      window.open('https://faq.voompcreators.com.br', '_blank');
    }
  },
  data() {
    return {
      dashboard: false,
      vendas: false,
      itemsSeller: [
        { route: "Sales", name: this.$t("side.text_1965") },
        { route: "Contracts", name: this.$t("side.text_1966") },
        { route: "Comissions", name: this.$t("side.text_1976") },
      ],
      itemsClient: [
        { route: "Client-Purchases", name: this.$t("side.text_1967") },
        { route: "Client-Subscriptions", name: this.$t("side.text_1968") },
      ],
    };
  },
};
</script>

<style scoped>
.container-menu {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px 10px;
  width: 100%;
  max-height: 100vh;
  padding-bottom: 150px;
  overflow: auto;
}
.Menu-item,
.Menu-item-logout {
  height: 70px;
}

.Menu-item a {
  color: #333 !important;
}
.Menu-item-logout {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 2px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}
.Menu-item-logout a {
  width: 100%;
  padding: 20px;
  display: flex;
  text-decoration: none;
  color: #333;
  font-size: 13px;
  font-weight: 600;
}
.Menu-item-logout img {
  margin-right: 20px;
  width: 17px;
}
.Menu-item-logout:hover {
  background-color: #ff0c37;
  cursor: pointer;
}
.Menu-item-logout:hover a {
  color: #fff;
}
.Menu-item-logout:hover img {
  filter: brightness(50) invert(0);
}
</style>
